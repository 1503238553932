/* tslint:disable */
/* eslint-disable */
/**
 * CGN Onboarding Portal Backoffice
 * Carta Giovani Nazionale Onboarding Portal Backoffice
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Agreement
 */
export interface Agreement {
    /**
     * 
     * @type {string}
     * @memberof Agreement
     */
    id: string;
    /**
     * 
     * @type {AgreementState}
     * @memberof Agreement
     */
    state: AgreementState;
    /**
     * 
     * @type {string}
     * @memberof Agreement
     */
    requestDate: string;
    /**
     * 
     * @type {Profile}
     * @memberof Agreement
     */
    profile?: Profile;
    /**
     * 
     * @type {Array<Discount>}
     * @memberof Agreement
     */
    discounts?: Array<Discount>;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Agreement
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {EntityType}
     * @memberof Agreement
     */
    entityType?: EntityType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AgreementState {
    PendingAgreement = 'PendingAgreement',
    AssignedAgreement = 'AssignedAgreement',
    ApprovedAgreement = 'ApprovedAgreement',
    RejectedAgreement = 'RejectedAgreement'
}

/**
 * 
 * @export
 * @interface Agreements
 */
export interface Agreements {
    /**
     * 
     * @type {Array<Agreement>}
     * @memberof Agreements
     */
    items: Array<Agreement>;
    /**
     * 
     * @type {number}
     * @memberof Agreements
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface ApprovedAgreement
 */
export interface ApprovedAgreement {
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreement
     */
    agreementId: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreement
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreement
     */
    agreementStartDate: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreement
     */
    agreementLastUpdateDate: string;
    /**
     * 
     * @type {number}
     * @memberof ApprovedAgreement
     */
    publishedDiscounts: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApprovedAgreement
     */
    testPending: boolean;
    /**
     * 
     * @type {EntityType}
     * @memberof ApprovedAgreement
     */
    entityType?: EntityType;
}
/**
 * 
 * @export
 * @interface ApprovedAgreementDetail
 */
export interface ApprovedAgreementDetail {
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDetail
     */
    agreementId: string;
    /**
     * 
     * @type {ApprovedAgreementProfile}
     * @memberof ApprovedAgreementDetail
     */
    profile: ApprovedAgreementProfile;
    /**
     * 
     * @type {Array<ApprovedAgreementDiscount>}
     * @memberof ApprovedAgreementDetail
     */
    discounts?: Array<ApprovedAgreementDiscount>;
    /**
     * 
     * @type {Array<Document>}
     * @memberof ApprovedAgreementDetail
     */
    documents: Array<Document>;
}
/**
 * 
 * @export
 * @interface ApprovedAgreementDiscount
 */
export interface ApprovedAgreementDiscount {
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDiscount
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDiscount
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDiscount
     */
    name_en?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDiscount
     */
    name_de?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDiscount
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDiscount
     */
    description_en?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDiscount
     */
    description_de?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDiscount
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDiscount
     */
    endDate: string;
    /**
     * 
     * @type {number}
     * @memberof ApprovedAgreementDiscount
     */
    discount?: number;
    /**
     * 
     * @type {Array<ProductCategory>}
     * @memberof ApprovedAgreementDiscount
     */
    productCategories: Array<ProductCategory>;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDiscount
     */
    condition: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDiscount
     */
    condition_en?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDiscount
     */
    condition_de?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDiscount
     */
    lastUpateDate: string;
    /**
     * 
     * @type {DiscountState}
     * @memberof ApprovedAgreementDiscount
     */
    state: DiscountState;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDiscount
     */
    discountUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDiscount
     */
    staticCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDiscount
     */
    landingPageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDiscount
     */
    landingPageReferrer?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementDiscount
     */
    testFailureReason?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApprovedAgreementDiscount
     */
    visibleOnEyca: boolean;
}
/**
 * 
 * @export
 * @interface ApprovedAgreementProfile
 */
export interface ApprovedAgreementProfile {
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementProfile
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementProfile
     */
    name_en?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementProfile
     */
    name_de?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementProfile
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementProfile
     */
    description_en: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementProfile
     */
    description_de: string;
    /**
     * 
     * @type {SalesChannel}
     * @memberof ApprovedAgreementProfile
     */
    salesChannel: SalesChannel;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementProfile
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementProfile
     */
    lastUpateDate: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementProfile
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementProfile
     */
    taxCodeOrVat: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementProfile
     */
    pecAddress: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementProfile
     */
    legalOffice: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementProfile
     */
    telephoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementProfile
     */
    legalRepresentativeFullName: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementProfile
     */
    legalRepresentativeTaxCode: string;
    /**
     * 
     * @type {ApprovedAgreementReferent}
     * @memberof ApprovedAgreementProfile
     */
    referent: ApprovedAgreementReferent;
    /**
     * 
     * @type {EntityType}
     * @memberof ApprovedAgreementProfile
     */
    entityType?: EntityType;
}
/**
 * 
 * @export
 * @interface ApprovedAgreementReferent
 */
export interface ApprovedAgreementReferent {
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementReferent
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementReferent
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementReferent
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementReferent
     */
    telephoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementReferent
     */
    role: string;
}
/**
 * 
 * @export
 * @interface ApprovedAgreements
 */
export interface ApprovedAgreements {
    /**
     * 
     * @type {Array<ApprovedAgreement>}
     * @memberof ApprovedAgreements
     */
    items: Array<ApprovedAgreement>;
    /**
     * 
     * @type {number}
     * @memberof ApprovedAgreements
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface AssignedAgreement
 */
export interface AssignedAgreement extends Agreement {
    /**
     * 
     * @type {Assignee}
     * @memberof AssignedAgreement
     */
    assignee: Assignee;
}
/**
 * 
 * @export
 * @interface AssignedAgreementAllOf
 */
export interface AssignedAgreementAllOf {
    /**
     * 
     * @type {Assignee}
     * @memberof AssignedAgreementAllOf
     */
    assignee: Assignee;
}
/**
 * 
 * @export
 * @interface Assignee
 */
export interface Assignee {
    /**
     * 
     * @type {string}
     * @memberof Assignee
     */
    fullName: string;
}
/**
 * 
 * @export
 * @interface BothChannels
 */
export interface BothChannels extends SalesChannel {
    /**
     * 
     * @type {string}
     * @memberof BothChannels
     */
    websiteUrl: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BothChannels
     */
    addresses: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof BothChannels
     */
    allNationalAddresses?: boolean;
    /**
     * 
     * @type {DiscountCodeType}
     * @memberof BothChannels
     */
    discountCodeType?: DiscountCodeType;
}
/**
 * 
 * @export
 * @interface BothChannelsAllOf
 */
export interface BothChannelsAllOf {
    /**
     * 
     * @type {string}
     * @memberof BothChannelsAllOf
     */
    websiteUrl: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BothChannelsAllOf
     */
    addresses: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof BothChannelsAllOf
     */
    allNationalAddresses?: boolean;
    /**
     * 
     * @type {DiscountCodeType}
     * @memberof BothChannelsAllOf
     */
    discountCodeType?: DiscountCodeType;
}
/**
 * 
 * @export
 * @interface BucketCode
 */
export interface BucketCode {
    /**
     * 
     * @type {string}
     * @memberof BucketCode
     */
    code: string;
}
/**
 * 
 * @export
 * @interface Discount
 */
export interface Discount {
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    agreementId: string;
    /**
     * 
     * @type {DiscountState}
     * @memberof Discount
     */
    state: DiscountState;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    name_en?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    name_de?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DiscountCodeType {
    Static = 'Static',
    Api = 'API',
    LandingPage = 'LandingPage',
    Bucket = 'Bucket'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum DiscountState {
    Draft = 'draft',
    Published = 'published',
    Suspended = 'suspended',
    Expired = 'expired',
    TestPending = 'test_pending',
    TestPassed = 'test_passed',
    TestFailed = 'test_failed'
}

/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {DocumentType}
     * @memberof Document
     */
    documentType: DocumentType;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    documentUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    creationDate: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DocumentType {
    Agreement = 'Agreement',
    AdhesionRequest = 'AdhesionRequest'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum EntityType {
    Private = 'Private',
    PublicAdministration = 'PublicAdministration'
}

/**
 * 
 * @export
 * @interface FailureReason
 */
export interface FailureReason {
    /**
     * 
     * @type {string}
     * @memberof FailureReason
     */
    reasonMessage: string;
}
/**
 * 
 * @export
 * @interface KeyOrganizationFiscalCode
 */
export interface KeyOrganizationFiscalCode {
    /**
     * 
     * @type {string}
     * @memberof KeyOrganizationFiscalCode
     */
    keyOrganizationFiscalCode: string;
}
/**
 * 
 * @export
 * @interface OfflineChannel
 */
export interface OfflineChannel extends SalesChannel {
    /**
     * 
     * @type {string}
     * @memberof OfflineChannel
     */
    websiteUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OfflineChannel
     */
    addresses: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof OfflineChannel
     */
    allNationalAddresses?: boolean;
}
/**
 * 
 * @export
 * @interface OfflineChannelAllOf
 */
export interface OfflineChannelAllOf {
    /**
     * 
     * @type {string}
     * @memberof OfflineChannelAllOf
     */
    websiteUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OfflineChannelAllOf
     */
    addresses: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof OfflineChannelAllOf
     */
    allNationalAddresses?: boolean;
}
/**
 * 
 * @export
 * @interface OnlineChannel
 */
export interface OnlineChannel extends SalesChannel {
    /**
     * 
     * @type {string}
     * @memberof OnlineChannel
     */
    websiteUrl: string;
    /**
     * 
     * @type {DiscountCodeType}
     * @memberof OnlineChannel
     */
    discountCodeType: DiscountCodeType;
}
/**
 * 
 * @export
 * @interface OnlineChannelAllOf
 */
export interface OnlineChannelAllOf {
    /**
     * 
     * @type {string}
     * @memberof OnlineChannelAllOf
     */
    websiteUrl: string;
    /**
     * 
     * @type {DiscountCodeType}
     * @memberof OnlineChannelAllOf
     */
    discountCodeType: DiscountCodeType;
}
/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    organizationFiscalCode: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    pec: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    insertedAt?: string;
    /**
     * 
     * @type {EntityType}
     * @memberof Organization
     */
    entityType?: EntityType;
}
/**
 * 
 * @export
 * @interface OrganizationAllOf
 */
export interface OrganizationAllOf {
    /**
     * 
     * @type {string}
     * @memberof OrganizationAllOf
     */
    pec: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAllOf
     */
    insertedAt?: string;
    /**
     * 
     * @type {EntityType}
     * @memberof OrganizationAllOf
     */
    entityType?: EntityType;
}
/**
 * 
 * @export
 * @interface OrganizationFiscalCode
 */
export interface OrganizationFiscalCode {
    /**
     * 
     * @type {string}
     * @memberof OrganizationFiscalCode
     */
    organizationFiscalCode: string;
}
/**
 * 
 * @export
 * @interface OrganizationName
 */
export interface OrganizationName {
    /**
     * 
     * @type {string}
     * @memberof OrganizationName
     */
    organizationName: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OrganizationStatus {
    Enabled = 'Enabled',
    Draft = 'Draft',
    Pending = 'Pending',
    Active = 'Active'
}

/**
 * 
 * @export
 * @interface OrganizationWithReferents
 */
export interface OrganizationWithReferents {
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithReferents
     */
    keyOrganizationFiscalCode: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithReferents
     */
    organizationFiscalCode: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithReferents
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithReferents
     */
    pec: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithReferents
     */
    insertedAt?: string;
    /**
     * 
     * @type {EntityType}
     * @memberof OrganizationWithReferents
     */
    entityType?: EntityType;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationWithReferents
     */
    referents: Array<string>;
}
/**
 * 
 * @export
 * @interface OrganizationWithReferentsAllOf
 */
export interface OrganizationWithReferentsAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationWithReferentsAllOf
     */
    referents: Array<string>;
}
/**
 * 
 * @export
 * @interface OrganizationWithReferentsAndStatus
 */
export interface OrganizationWithReferentsAndStatus {
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithReferentsAndStatus
     */
    keyOrganizationFiscalCode: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithReferentsAndStatus
     */
    organizationFiscalCode: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithReferentsAndStatus
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithReferentsAndStatus
     */
    pec: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithReferentsAndStatus
     */
    insertedAt?: string;
    /**
     * 
     * @type {EntityType}
     * @memberof OrganizationWithReferentsAndStatus
     */
    entityType?: EntityType;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationWithReferentsAndStatus
     */
    referents: Array<string>;
    /**
     * 
     * @type {OrganizationStatus}
     * @memberof OrganizationWithReferentsAndStatus
     */
    status: OrganizationStatus;
}
/**
 * 
 * @export
 * @interface OrganizationWithReferentsAndStatusAllOf
 */
export interface OrganizationWithReferentsAndStatusAllOf {
    /**
     * 
     * @type {OrganizationStatus}
     * @memberof OrganizationWithReferentsAndStatusAllOf
     */
    status: OrganizationStatus;
}
/**
 * 
 * @export
 * @interface Organizations
 */
export interface Organizations {
    /**
     * 
     * @type {Array<OrganizationWithReferentsAndStatus>}
     * @memberof Organizations
     */
    items?: Array<OrganizationWithReferentsAndStatus>;
    /**
     * 
     * @type {number}
     * @memberof Organizations
     */
    count?: number;
}
/**
 * 
 * @export
 * @interface PendingAgreement
 */
export interface PendingAgreement extends Agreement {
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ProductCategory {
    BankingServices = 'BankingServices',
    CultureAndEntertainment = 'CultureAndEntertainment',
    Health = 'Health',
    Home = 'Home',
    JobOffers = 'JobOffers',
    Learning = 'Learning',
    Sports = 'Sports',
    SustainableMobility = 'SustainableMobility',
    TelephonyAndInternet = 'TelephonyAndInternet',
    Travelling = 'Travelling'
}

/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    agreementId: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    fullName: string;
    /**
     * 
     * @type {Referent}
     * @memberof Profile
     */
    referent: Referent;
}
/**
 * 
 * @export
 * @interface Referent
 */
export interface Referent {
    /**
     * 
     * @type {string}
     * @memberof Referent
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Referent
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof Referent
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof Referent
     */
    telephoneNumber: string;
}
/**
 * 
 * @export
 * @interface ReferentFiscalCode
 */
export interface ReferentFiscalCode {
    /**
     * 
     * @type {string}
     * @memberof ReferentFiscalCode
     */
    referentFiscalCode?: string;
}
/**
 * 
 * @export
 * @interface RefuseAgreement
 */
export interface RefuseAgreement {
    /**
     * 
     * @type {string}
     * @memberof RefuseAgreement
     */
    reasonMessage: string;
}
/**
 * 
 * @export
 * @interface SalesChannel
 */
export interface SalesChannel {
    /**
     * 
     * @type {SalesChannelType}
     * @memberof SalesChannel
     */
    channelType: SalesChannelType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SalesChannelType {
    OnlineChannel = 'OnlineChannel',
    OfflineChannel = 'OfflineChannel',
    BothChannels = 'BothChannels'
}

/**
 * 
 * @export
 * @interface SuspendDiscount
 */
export interface SuspendDiscount {
    /**
     * 
     * @type {string}
     * @memberof SuspendDiscount
     */
    reasonMessage: string;
}

/**
 * AgreementApi - axios parameter creator
 * @export
 */
export const AgreementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Approve an agreement
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveAgreement: async (agreementId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('approveAgreement', 'agreementId', agreementId)
            const localVarPath = `/agreement-requests/{agreementId}/approval`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign an agreement to the user
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignAgreement: async (agreementId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('assignAgreement', 'agreementId', agreementId)
            const localVarPath = `/agreement-requests/{agreementId}/assignee`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of agreements
         * @param {string} [states] Values from &#x60;AgreementState&#x60;
         * @param {'Me' | 'Others'} [assignee] Assigned agreement assignee
         * @param {string} [profileFullName] String to search in profile full name
         * @param {string} [requestDateFrom] Agreement request date lower bound
         * @param {string} [requestDateTo] Agreement request date upper bound
         * @param {number} [pageSize] Page size
         * @param {number} [page] Page number
         * @param {'Operator' | 'RequestDate' | 'State' | 'Assignee'} [sortColumn] Sort by column
         * @param {'ASC' | 'DESC'} [sortDirection] Sort Direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgreements: async (states?: string, assignee?: 'Me' | 'Others', profileFullName?: string, requestDateFrom?: string, requestDateTo?: string, pageSize?: number, page?: number, sortColumn?: 'Operator' | 'RequestDate' | 'State' | 'Assignee', sortDirection?: 'ASC' | 'DESC', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/agreement-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)

            if (states !== undefined) {
                localVarQueryParameter['states'] = states;
            }

            if (assignee !== undefined) {
                localVarQueryParameter['assignee'] = assignee;
            }

            if (profileFullName !== undefined) {
                localVarQueryParameter['profileFullName'] = profileFullName;
            }

            if (requestDateFrom !== undefined) {
                localVarQueryParameter['requestDateFrom'] = (requestDateFrom as any instanceof Date) ?
                    (requestDateFrom as any).toISOString().substr(0,10) :
                    requestDateFrom;
            }

            if (requestDateTo !== undefined) {
                localVarQueryParameter['requestDateTo'] = (requestDateTo as any instanceof Date) ?
                    (requestDateTo as any).toISOString().substr(0,10) :
                    requestDateTo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['sortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get approved agreement
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApprovedAgreement: async (agreementId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('getApprovedAgreement', 'agreementId', agreementId)
            const localVarPath = `/approved-agreements/{agreementId}`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of approved agreements
         * @param {string} [profileFullName] String to search in profile full name
         * @param {string} [lastUpdateDateFrom] Agreement last update date lower bound
         * @param {string} [lastUpdateDateTo] Agreement last update date upper bound
         * @param {number} [pageSize] Page size
         * @param {number} [page] Page number
         * @param {'Operator' | 'AgreementDate' | 'LastModifyDate' | 'PublishedDiscounts'} [sortColumn] Sort by column
         * @param {'ASC' | 'DESC'} [sortDirection] Sort Direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApprovedAgreements: async (profileFullName?: string, lastUpdateDateFrom?: string, lastUpdateDateTo?: string, pageSize?: number, page?: number, sortColumn?: 'Operator' | 'AgreementDate' | 'LastModifyDate' | 'PublishedDiscounts', sortDirection?: 'ASC' | 'DESC', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/approved-agreements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)

            if (profileFullName !== undefined) {
                localVarQueryParameter['profileFullName'] = profileFullName;
            }

            if (lastUpdateDateFrom !== undefined) {
                localVarQueryParameter['lastUpdateDateFrom'] = (lastUpdateDateFrom as any instanceof Date) ?
                    (lastUpdateDateFrom as any).toISOString().substr(0,10) :
                    lastUpdateDateFrom;
            }

            if (lastUpdateDateTo !== undefined) {
                localVarQueryParameter['lastUpdateDateTo'] = (lastUpdateDateTo as any instanceof Date) ?
                    (lastUpdateDateTo as any).toISOString().substr(0,10) :
                    lastUpdateDateTo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['sortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reject an agreement
         * @param {string} agreementId Agreement id
         * @param {RefuseAgreement} refusal Agreement refusal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectAgreement: async (agreementId: string, refusal: RefuseAgreement, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('rejectAgreement', 'agreementId', agreementId)
            // verify required parameter 'refusal' is not null or undefined
            assertParamExists('rejectAgreement', 'refusal', refusal)
            const localVarPath = `/agreement-requests/{agreementId}/refusal`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refusal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unassign an agreement to the user
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignAgreement: async (agreementId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('unassignAgreement', 'agreementId', agreementId)
            const localVarPath = `/agreement-requests/{agreementId}/assignee`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgreementApi - functional programming interface
 * @export
 */
export const AgreementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgreementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Approve an agreement
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveAgreement(agreementId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveAgreement(agreementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assign an agreement to the user
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignAgreement(agreementId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignAgreement(agreementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of agreements
         * @param {string} [states] Values from &#x60;AgreementState&#x60;
         * @param {'Me' | 'Others'} [assignee] Assigned agreement assignee
         * @param {string} [profileFullName] String to search in profile full name
         * @param {string} [requestDateFrom] Agreement request date lower bound
         * @param {string} [requestDateTo] Agreement request date upper bound
         * @param {number} [pageSize] Page size
         * @param {number} [page] Page number
         * @param {'Operator' | 'RequestDate' | 'State' | 'Assignee'} [sortColumn] Sort by column
         * @param {'ASC' | 'DESC'} [sortDirection] Sort Direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgreements(states?: string, assignee?: 'Me' | 'Others', profileFullName?: string, requestDateFrom?: string, requestDateTo?: string, pageSize?: number, page?: number, sortColumn?: 'Operator' | 'RequestDate' | 'State' | 'Assignee', sortDirection?: 'ASC' | 'DESC', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Agreements>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgreements(states, assignee, profileFullName, requestDateFrom, requestDateTo, pageSize, page, sortColumn, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get approved agreement
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApprovedAgreement(agreementId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApprovedAgreementDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApprovedAgreement(agreementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of approved agreements
         * @param {string} [profileFullName] String to search in profile full name
         * @param {string} [lastUpdateDateFrom] Agreement last update date lower bound
         * @param {string} [lastUpdateDateTo] Agreement last update date upper bound
         * @param {number} [pageSize] Page size
         * @param {number} [page] Page number
         * @param {'Operator' | 'AgreementDate' | 'LastModifyDate' | 'PublishedDiscounts'} [sortColumn] Sort by column
         * @param {'ASC' | 'DESC'} [sortDirection] Sort Direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApprovedAgreements(profileFullName?: string, lastUpdateDateFrom?: string, lastUpdateDateTo?: string, pageSize?: number, page?: number, sortColumn?: 'Operator' | 'AgreementDate' | 'LastModifyDate' | 'PublishedDiscounts', sortDirection?: 'ASC' | 'DESC', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApprovedAgreements>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApprovedAgreements(profileFullName, lastUpdateDateFrom, lastUpdateDateTo, pageSize, page, sortColumn, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reject an agreement
         * @param {string} agreementId Agreement id
         * @param {RefuseAgreement} refusal Agreement refusal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectAgreement(agreementId: string, refusal: RefuseAgreement, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectAgreement(agreementId, refusal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unassign an agreement to the user
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unassignAgreement(agreementId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unassignAgreement(agreementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgreementApi - factory interface
 * @export
 */
export const AgreementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgreementApiFp(configuration)
    return {
        /**
         * 
         * @summary Approve an agreement
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveAgreement(agreementId: string, options?: any): AxiosPromise<void> {
            return localVarFp.approveAgreement(agreementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign an agreement to the user
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignAgreement(agreementId: string, options?: any): AxiosPromise<void> {
            return localVarFp.assignAgreement(agreementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of agreements
         * @param {string} [states] Values from &#x60;AgreementState&#x60;
         * @param {'Me' | 'Others'} [assignee] Assigned agreement assignee
         * @param {string} [profileFullName] String to search in profile full name
         * @param {string} [requestDateFrom] Agreement request date lower bound
         * @param {string} [requestDateTo] Agreement request date upper bound
         * @param {number} [pageSize] Page size
         * @param {number} [page] Page number
         * @param {'Operator' | 'RequestDate' | 'State' | 'Assignee'} [sortColumn] Sort by column
         * @param {'ASC' | 'DESC'} [sortDirection] Sort Direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgreements(states?: string, assignee?: 'Me' | 'Others', profileFullName?: string, requestDateFrom?: string, requestDateTo?: string, pageSize?: number, page?: number, sortColumn?: 'Operator' | 'RequestDate' | 'State' | 'Assignee', sortDirection?: 'ASC' | 'DESC', options?: any): AxiosPromise<Agreements> {
            return localVarFp.getAgreements(states, assignee, profileFullName, requestDateFrom, requestDateTo, pageSize, page, sortColumn, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get approved agreement
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApprovedAgreement(agreementId: string, options?: any): AxiosPromise<ApprovedAgreementDetail> {
            return localVarFp.getApprovedAgreement(agreementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of approved agreements
         * @param {string} [profileFullName] String to search in profile full name
         * @param {string} [lastUpdateDateFrom] Agreement last update date lower bound
         * @param {string} [lastUpdateDateTo] Agreement last update date upper bound
         * @param {number} [pageSize] Page size
         * @param {number} [page] Page number
         * @param {'Operator' | 'AgreementDate' | 'LastModifyDate' | 'PublishedDiscounts'} [sortColumn] Sort by column
         * @param {'ASC' | 'DESC'} [sortDirection] Sort Direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApprovedAgreements(profileFullName?: string, lastUpdateDateFrom?: string, lastUpdateDateTo?: string, pageSize?: number, page?: number, sortColumn?: 'Operator' | 'AgreementDate' | 'LastModifyDate' | 'PublishedDiscounts', sortDirection?: 'ASC' | 'DESC', options?: any): AxiosPromise<ApprovedAgreements> {
            return localVarFp.getApprovedAgreements(profileFullName, lastUpdateDateFrom, lastUpdateDateTo, pageSize, page, sortColumn, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reject an agreement
         * @param {string} agreementId Agreement id
         * @param {RefuseAgreement} refusal Agreement refusal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectAgreement(agreementId: string, refusal: RefuseAgreement, options?: any): AxiosPromise<void> {
            return localVarFp.rejectAgreement(agreementId, refusal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unassign an agreement to the user
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignAgreement(agreementId: string, options?: any): AxiosPromise<void> {
            return localVarFp.unassignAgreement(agreementId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgreementApi - object-oriented interface
 * @export
 * @class AgreementApi
 * @extends {BaseAPI}
 */
export class AgreementApi extends BaseAPI {
    /**
     * 
     * @summary Approve an agreement
     * @param {string} agreementId Agreement id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementApi
     */
    public approveAgreement(agreementId: string, options?: any) {
        return AgreementApiFp(this.configuration).approveAgreement(agreementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign an agreement to the user
     * @param {string} agreementId Agreement id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementApi
     */
    public assignAgreement(agreementId: string, options?: any) {
        return AgreementApiFp(this.configuration).assignAgreement(agreementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of agreements
     * @param {string} [states] Values from &#x60;AgreementState&#x60;
     * @param {'Me' | 'Others'} [assignee] Assigned agreement assignee
     * @param {string} [profileFullName] String to search in profile full name
     * @param {string} [requestDateFrom] Agreement request date lower bound
     * @param {string} [requestDateTo] Agreement request date upper bound
     * @param {number} [pageSize] Page size
     * @param {number} [page] Page number
     * @param {'Operator' | 'RequestDate' | 'State' | 'Assignee'} [sortColumn] Sort by column
     * @param {'ASC' | 'DESC'} [sortDirection] Sort Direction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementApi
     */
    public getAgreements(states?: string, assignee?: 'Me' | 'Others', profileFullName?: string, requestDateFrom?: string, requestDateTo?: string, pageSize?: number, page?: number, sortColumn?: 'Operator' | 'RequestDate' | 'State' | 'Assignee', sortDirection?: 'ASC' | 'DESC', options?: any) {
        return AgreementApiFp(this.configuration).getAgreements(states, assignee, profileFullName, requestDateFrom, requestDateTo, pageSize, page, sortColumn, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get approved agreement
     * @param {string} agreementId Agreement id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementApi
     */
    public getApprovedAgreement(agreementId: string, options?: any) {
        return AgreementApiFp(this.configuration).getApprovedAgreement(agreementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of approved agreements
     * @param {string} [profileFullName] String to search in profile full name
     * @param {string} [lastUpdateDateFrom] Agreement last update date lower bound
     * @param {string} [lastUpdateDateTo] Agreement last update date upper bound
     * @param {number} [pageSize] Page size
     * @param {number} [page] Page number
     * @param {'Operator' | 'AgreementDate' | 'LastModifyDate' | 'PublishedDiscounts'} [sortColumn] Sort by column
     * @param {'ASC' | 'DESC'} [sortDirection] Sort Direction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementApi
     */
    public getApprovedAgreements(profileFullName?: string, lastUpdateDateFrom?: string, lastUpdateDateTo?: string, pageSize?: number, page?: number, sortColumn?: 'Operator' | 'AgreementDate' | 'LastModifyDate' | 'PublishedDiscounts', sortDirection?: 'ASC' | 'DESC', options?: any) {
        return AgreementApiFp(this.configuration).getApprovedAgreements(profileFullName, lastUpdateDateFrom, lastUpdateDateTo, pageSize, page, sortColumn, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reject an agreement
     * @param {string} agreementId Agreement id
     * @param {RefuseAgreement} refusal Agreement refusal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementApi
     */
    public rejectAgreement(agreementId: string, refusal: RefuseAgreement, options?: any) {
        return AgreementApiFp(this.configuration).rejectAgreement(agreementId, refusal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unassign an agreement to the user
     * @param {string} agreementId Agreement id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementApi
     */
    public unassignAgreement(agreementId: string, options?: any) {
        return AgreementApiFp(this.configuration).unassignAgreement(agreementId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AttributeauthorityApi - axios parameter creator
 * @export
 */
export const AttributeauthorityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete an organization
         * @summary Delete organization.
         * @param {string} keyOrganizationFiscalCode Organization fiscal code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganization: async (keyOrganizationFiscalCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyOrganizationFiscalCode' is not null or undefined
            assertParamExists('deleteOrganization', 'keyOrganizationFiscalCode', keyOrganizationFiscalCode)
            const localVarPath = `/organization/{keyOrganizationFiscalCode}`
                .replace(`{${"keyOrganizationFiscalCode"}}`, encodeURIComponent(String(keyOrganizationFiscalCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a referent
         * @summary Delete referent.
         * @param {string} keyOrganizationFiscalCode Organization fiscal code
         * @param {string} referentFiscalCode Referent fiscal code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReferent: async (keyOrganizationFiscalCode: string, referentFiscalCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyOrganizationFiscalCode' is not null or undefined
            assertParamExists('deleteReferent', 'keyOrganizationFiscalCode', keyOrganizationFiscalCode)
            // verify required parameter 'referentFiscalCode' is not null or undefined
            assertParamExists('deleteReferent', 'referentFiscalCode', referentFiscalCode)
            const localVarPath = `/organization/{keyOrganizationFiscalCode}/referents/{referentFiscalCode}`
                .replace(`{${"keyOrganizationFiscalCode"}}`, encodeURIComponent(String(keyOrganizationFiscalCode)))
                .replace(`{${"referentFiscalCode"}}`, encodeURIComponent(String(referentFiscalCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an organization by fiscal code
         * @summary Get organization
         * @param {string} keyOrganizationFiscalCode Organization fiscal code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization: async (keyOrganizationFiscalCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyOrganizationFiscalCode' is not null or undefined
            assertParamExists('getOrganization', 'keyOrganizationFiscalCode', keyOrganizationFiscalCode)
            const localVarPath = `/organization/{keyOrganizationFiscalCode}`
                .replace(`{${"keyOrganizationFiscalCode"}}`, encodeURIComponent(String(keyOrganizationFiscalCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all organizations
         * @summary Get organizations
         * @param {string} [searchQuery] String to search in organization name o fiscal code
         * @param {number} [page] Page number
         * @param {number} [pageSize] Page size
         * @param {'fiscalCode' | 'name' | 'pec' | 'insertedAt'} [sortBy] The field to sort by
         * @param {'ASC' | 'DESC'} [sortDirection] Sort Direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations: async (searchQuery?: string, page?: number, pageSize?: number, sortBy?: 'fiscalCode' | 'name' | 'pec' | 'insertedAt', sortDirection?: 'ASC' | 'DESC', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all referents for a organization
         * @summary Get referents
         * @param {string} keyOrganizationFiscalCode Organization fiscal code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferents: async (keyOrganizationFiscalCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyOrganizationFiscalCode' is not null or undefined
            assertParamExists('getReferents', 'keyOrganizationFiscalCode', keyOrganizationFiscalCode)
            const localVarPath = `/organization/{keyOrganizationFiscalCode}/referents`
                .replace(`{${"keyOrganizationFiscalCode"}}`, encodeURIComponent(String(keyOrganizationFiscalCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Insert a referent fiscalcode.
         * @summary Insert referent.
         * @param {string} keyOrganizationFiscalCode Organization fiscal code
         * @param {ReferentFiscalCode} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertReferent: async (keyOrganizationFiscalCode: string, body: ReferentFiscalCode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyOrganizationFiscalCode' is not null or undefined
            assertParamExists('insertReferent', 'keyOrganizationFiscalCode', keyOrganizationFiscalCode)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('insertReferent', 'body', body)
            const localVarPath = `/organization/{keyOrganizationFiscalCode}/referents`
                .replace(`{${"keyOrganizationFiscalCode"}}`, encodeURIComponent(String(keyOrganizationFiscalCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update or insert an organization.
         * @summary Upsert organization.
         * @param {OrganizationWithReferents} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertOrganization: async (body?: OrganizationWithReferents, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttributeauthorityApi - functional programming interface
 * @export
 */
export const AttributeauthorityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttributeauthorityApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete an organization
         * @summary Delete organization.
         * @param {string} keyOrganizationFiscalCode Organization fiscal code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganization(keyOrganizationFiscalCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganization(keyOrganizationFiscalCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a referent
         * @summary Delete referent.
         * @param {string} keyOrganizationFiscalCode Organization fiscal code
         * @param {string} referentFiscalCode Referent fiscal code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReferent(keyOrganizationFiscalCode: string, referentFiscalCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReferent(keyOrganizationFiscalCode, referentFiscalCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an organization by fiscal code
         * @summary Get organization
         * @param {string} keyOrganizationFiscalCode Organization fiscal code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganization(keyOrganizationFiscalCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationWithReferentsAndStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganization(keyOrganizationFiscalCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all organizations
         * @summary Get organizations
         * @param {string} [searchQuery] String to search in organization name o fiscal code
         * @param {number} [page] Page number
         * @param {number} [pageSize] Page size
         * @param {'fiscalCode' | 'name' | 'pec' | 'insertedAt'} [sortBy] The field to sort by
         * @param {'ASC' | 'DESC'} [sortDirection] Sort Direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizations(searchQuery?: string, page?: number, pageSize?: number, sortBy?: 'fiscalCode' | 'name' | 'pec' | 'insertedAt', sortDirection?: 'ASC' | 'DESC', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organizations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizations(searchQuery, page, pageSize, sortBy, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all referents for a organization
         * @summary Get referents
         * @param {string} keyOrganizationFiscalCode Organization fiscal code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferents(keyOrganizationFiscalCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferents(keyOrganizationFiscalCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Insert a referent fiscalcode.
         * @summary Insert referent.
         * @param {string} keyOrganizationFiscalCode Organization fiscal code
         * @param {ReferentFiscalCode} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertReferent(keyOrganizationFiscalCode: string, body: ReferentFiscalCode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertReferent(keyOrganizationFiscalCode, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update or insert an organization.
         * @summary Upsert organization.
         * @param {OrganizationWithReferents} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertOrganization(body?: OrganizationWithReferents, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationWithReferents>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertOrganization(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AttributeauthorityApi - factory interface
 * @export
 */
export const AttributeauthorityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttributeauthorityApiFp(configuration)
    return {
        /**
         * Delete an organization
         * @summary Delete organization.
         * @param {string} keyOrganizationFiscalCode Organization fiscal code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganization(keyOrganizationFiscalCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOrganization(keyOrganizationFiscalCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a referent
         * @summary Delete referent.
         * @param {string} keyOrganizationFiscalCode Organization fiscal code
         * @param {string} referentFiscalCode Referent fiscal code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReferent(keyOrganizationFiscalCode: string, referentFiscalCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReferent(keyOrganizationFiscalCode, referentFiscalCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an organization by fiscal code
         * @summary Get organization
         * @param {string} keyOrganizationFiscalCode Organization fiscal code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization(keyOrganizationFiscalCode: string, options?: any): AxiosPromise<OrganizationWithReferentsAndStatus> {
            return localVarFp.getOrganization(keyOrganizationFiscalCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all organizations
         * @summary Get organizations
         * @param {string} [searchQuery] String to search in organization name o fiscal code
         * @param {number} [page] Page number
         * @param {number} [pageSize] Page size
         * @param {'fiscalCode' | 'name' | 'pec' | 'insertedAt'} [sortBy] The field to sort by
         * @param {'ASC' | 'DESC'} [sortDirection] Sort Direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations(searchQuery?: string, page?: number, pageSize?: number, sortBy?: 'fiscalCode' | 'name' | 'pec' | 'insertedAt', sortDirection?: 'ASC' | 'DESC', options?: any): AxiosPromise<Organizations> {
            return localVarFp.getOrganizations(searchQuery, page, pageSize, sortBy, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all referents for a organization
         * @summary Get referents
         * @param {string} keyOrganizationFiscalCode Organization fiscal code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferents(keyOrganizationFiscalCode: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getReferents(keyOrganizationFiscalCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Insert a referent fiscalcode.
         * @summary Insert referent.
         * @param {string} keyOrganizationFiscalCode Organization fiscal code
         * @param {ReferentFiscalCode} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertReferent(keyOrganizationFiscalCode: string, body: ReferentFiscalCode, options?: any): AxiosPromise<void> {
            return localVarFp.insertReferent(keyOrganizationFiscalCode, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Update or insert an organization.
         * @summary Upsert organization.
         * @param {OrganizationWithReferents} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertOrganization(body?: OrganizationWithReferents, options?: any): AxiosPromise<OrganizationWithReferents> {
            return localVarFp.upsertOrganization(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AttributeauthorityApi - object-oriented interface
 * @export
 * @class AttributeauthorityApi
 * @extends {BaseAPI}
 */
export class AttributeauthorityApi extends BaseAPI {
    /**
     * Delete an organization
     * @summary Delete organization.
     * @param {string} keyOrganizationFiscalCode Organization fiscal code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttributeauthorityApi
     */
    public deleteOrganization(keyOrganizationFiscalCode: string, options?: any) {
        return AttributeauthorityApiFp(this.configuration).deleteOrganization(keyOrganizationFiscalCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a referent
     * @summary Delete referent.
     * @param {string} keyOrganizationFiscalCode Organization fiscal code
     * @param {string} referentFiscalCode Referent fiscal code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttributeauthorityApi
     */
    public deleteReferent(keyOrganizationFiscalCode: string, referentFiscalCode: string, options?: any) {
        return AttributeauthorityApiFp(this.configuration).deleteReferent(keyOrganizationFiscalCode, referentFiscalCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an organization by fiscal code
     * @summary Get organization
     * @param {string} keyOrganizationFiscalCode Organization fiscal code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttributeauthorityApi
     */
    public getOrganization(keyOrganizationFiscalCode: string, options?: any) {
        return AttributeauthorityApiFp(this.configuration).getOrganization(keyOrganizationFiscalCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all organizations
     * @summary Get organizations
     * @param {string} [searchQuery] String to search in organization name o fiscal code
     * @param {number} [page] Page number
     * @param {number} [pageSize] Page size
     * @param {'fiscalCode' | 'name' | 'pec' | 'insertedAt'} [sortBy] The field to sort by
     * @param {'ASC' | 'DESC'} [sortDirection] Sort Direction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttributeauthorityApi
     */
    public getOrganizations(searchQuery?: string, page?: number, pageSize?: number, sortBy?: 'fiscalCode' | 'name' | 'pec' | 'insertedAt', sortDirection?: 'ASC' | 'DESC', options?: any) {
        return AttributeauthorityApiFp(this.configuration).getOrganizations(searchQuery, page, pageSize, sortBy, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all referents for a organization
     * @summary Get referents
     * @param {string} keyOrganizationFiscalCode Organization fiscal code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttributeauthorityApi
     */
    public getReferents(keyOrganizationFiscalCode: string, options?: any) {
        return AttributeauthorityApiFp(this.configuration).getReferents(keyOrganizationFiscalCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Insert a referent fiscalcode.
     * @summary Insert referent.
     * @param {string} keyOrganizationFiscalCode Organization fiscal code
     * @param {ReferentFiscalCode} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttributeauthorityApi
     */
    public insertReferent(keyOrganizationFiscalCode: string, body: ReferentFiscalCode, options?: any) {
        return AttributeauthorityApiFp(this.configuration).insertReferent(keyOrganizationFiscalCode, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update or insert an organization.
     * @summary Upsert organization.
     * @param {OrganizationWithReferents} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttributeauthorityApi
     */
    public upsertOrganization(body?: OrganizationWithReferents, options?: any) {
        return AttributeauthorityApiFp(this.configuration).upsertOrganization(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DiscountApi - axios parameter creator
 * @export
 */
export const DiscountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a bucket code for discount
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscountBucketCode: async (agreementId: string, discountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('getDiscountBucketCode', 'agreementId', agreementId)
            // verify required parameter 'discountId' is not null or undefined
            assertParamExists('getDiscountBucketCode', 'discountId', discountId)
            const localVarPath = `/agreement-requests/{agreementId}/discounts/{discountId}/bucket-code`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"discountId"}}`, encodeURIComponent(String(discountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark as test failed with a reason
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {FailureReason} failure Failure reason
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDiscountTestFailed: async (agreementId: string, discountId: string, failure: FailureReason, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('setDiscountTestFailed', 'agreementId', agreementId)
            // verify required parameter 'discountId' is not null or undefined
            assertParamExists('setDiscountTestFailed', 'discountId', discountId)
            // verify required parameter 'failure' is not null or undefined
            assertParamExists('setDiscountTestFailed', 'failure', failure)
            const localVarPath = `/agreement-requests/{agreementId}/discounts/{discountId}/test-failed`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"discountId"}}`, encodeURIComponent(String(discountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failure, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark as test passed
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDiscountTestPassed: async (agreementId: string, discountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('setDiscountTestPassed', 'agreementId', agreementId)
            // verify required parameter 'discountId' is not null or undefined
            assertParamExists('setDiscountTestPassed', 'discountId', discountId)
            const localVarPath = `/agreement-requests/{agreementId}/discounts/{discountId}/test-passed`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"discountId"}}`, encodeURIComponent(String(discountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Suspend a discount
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {SuspendDiscount} suspension Discount suspension
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendDiscount: async (agreementId: string, discountId: string, suspension: SuspendDiscount, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('suspendDiscount', 'agreementId', agreementId)
            // verify required parameter 'discountId' is not null or undefined
            assertParamExists('suspendDiscount', 'discountId', discountId)
            // verify required parameter 'suspension' is not null or undefined
            assertParamExists('suspendDiscount', 'suspension', suspension)
            const localVarPath = `/agreement-requests/{agreementId}/discounts/{discountId}/suspension`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"discountId"}}`, encodeURIComponent(String(discountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(suspension, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscountApi - functional programming interface
 * @export
 */
export const DiscountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiscountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a bucket code for discount
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDiscountBucketCode(agreementId: string, discountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BucketCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDiscountBucketCode(agreementId, discountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark as test failed with a reason
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {FailureReason} failure Failure reason
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDiscountTestFailed(agreementId: string, discountId: string, failure: FailureReason, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDiscountTestFailed(agreementId, discountId, failure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark as test passed
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDiscountTestPassed(agreementId: string, discountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDiscountTestPassed(agreementId, discountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Suspend a discount
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {SuspendDiscount} suspension Discount suspension
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suspendDiscount(agreementId: string, discountId: string, suspension: SuspendDiscount, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suspendDiscount(agreementId, discountId, suspension, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DiscountApi - factory interface
 * @export
 */
export const DiscountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiscountApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a bucket code for discount
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscountBucketCode(agreementId: string, discountId: string, options?: any): AxiosPromise<BucketCode> {
            return localVarFp.getDiscountBucketCode(agreementId, discountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark as test failed with a reason
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {FailureReason} failure Failure reason
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDiscountTestFailed(agreementId: string, discountId: string, failure: FailureReason, options?: any): AxiosPromise<void> {
            return localVarFp.setDiscountTestFailed(agreementId, discountId, failure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark as test passed
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDiscountTestPassed(agreementId: string, discountId: string, options?: any): AxiosPromise<void> {
            return localVarFp.setDiscountTestPassed(agreementId, discountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Suspend a discount
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {SuspendDiscount} suspension Discount suspension
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendDiscount(agreementId: string, discountId: string, suspension: SuspendDiscount, options?: any): AxiosPromise<void> {
            return localVarFp.suspendDiscount(agreementId, discountId, suspension, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiscountApi - object-oriented interface
 * @export
 * @class DiscountApi
 * @extends {BaseAPI}
 */
export class DiscountApi extends BaseAPI {
    /**
     * 
     * @summary Get a bucket code for discount
     * @param {string} agreementId Agreement id
     * @param {string} discountId Discount id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public getDiscountBucketCode(agreementId: string, discountId: string, options?: any) {
        return DiscountApiFp(this.configuration).getDiscountBucketCode(agreementId, discountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark as test failed with a reason
     * @param {string} agreementId Agreement id
     * @param {string} discountId Discount id
     * @param {FailureReason} failure Failure reason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public setDiscountTestFailed(agreementId: string, discountId: string, failure: FailureReason, options?: any) {
        return DiscountApiFp(this.configuration).setDiscountTestFailed(agreementId, discountId, failure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark as test passed
     * @param {string} agreementId Agreement id
     * @param {string} discountId Discount id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public setDiscountTestPassed(agreementId: string, discountId: string, options?: any) {
        return DiscountApiFp(this.configuration).setDiscountTestPassed(agreementId, discountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Suspend a discount
     * @param {string} agreementId Agreement id
     * @param {string} discountId Discount id
     * @param {SuspendDiscount} suspension Discount suspension
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public suspendDiscount(agreementId: string, discountId: string, suspension: SuspendDiscount, options?: any) {
        return DiscountApiFp(this.configuration).suspendDiscount(agreementId, discountId, suspension, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a document
         * @param {string} agreementId Agreement id
         * @param {'Agreement' | 'AdhesionRequest'} documentType Document type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: async (agreementId: string, documentType: 'Agreement' | 'AdhesionRequest', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('deleteDocument', 'agreementId', agreementId)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('deleteDocument', 'documentType', documentType)
            const localVarPath = `/agreement-requests/{agreementId}/documents/{documentType}`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"documentType"}}`, encodeURIComponent(String(documentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get documents
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments: async (agreementId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('getDocuments', 'agreementId', agreementId)
            const localVarPath = `/agreement-requests/{agreementId}/documents`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a document
         * @param {string} agreementId Agreement id
         * @param {'Agreement' | 'AdhesionRequest'} documentType Document type
         * @param {any} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument: async (agreementId: string, documentType: 'Agreement' | 'AdhesionRequest', document: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('uploadDocument', 'agreementId', agreementId)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('uploadDocument', 'documentType', documentType)
            // verify required parameter 'document' is not null or undefined
            assertParamExists('uploadDocument', 'document', document)
            const localVarPath = `/agreement-requests/{agreementId}/documents/{documentType}`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"documentType"}}`, encodeURIComponent(String(documentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


            if (document !== undefined) { 
                localVarFormParams.append('document', document as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a document
         * @param {string} agreementId Agreement id
         * @param {'Agreement' | 'AdhesionRequest'} documentType Document type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocument(agreementId: string, documentType: 'Agreement' | 'AdhesionRequest', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocument(agreementId, documentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get documents
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocuments(agreementId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Document>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocuments(agreementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a document
         * @param {string} agreementId Agreement id
         * @param {'Agreement' | 'AdhesionRequest'} documentType Document type
         * @param {any} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDocument(agreementId: string, documentType: 'Agreement' | 'AdhesionRequest', document: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDocument(agreementId, documentType, document, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a document
         * @param {string} agreementId Agreement id
         * @param {'Agreement' | 'AdhesionRequest'} documentType Document type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(agreementId: string, documentType: 'Agreement' | 'AdhesionRequest', options?: any): AxiosPromise<void> {
            return localVarFp.deleteDocument(agreementId, documentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get documents
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments(agreementId: string, options?: any): AxiosPromise<Array<Document>> {
            return localVarFp.getDocuments(agreementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a document
         * @param {string} agreementId Agreement id
         * @param {'Agreement' | 'AdhesionRequest'} documentType Document type
         * @param {any} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument(agreementId: string, documentType: 'Agreement' | 'AdhesionRequest', document: any, options?: any): AxiosPromise<Document> {
            return localVarFp.uploadDocument(agreementId, documentType, document, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
    /**
     * 
     * @summary Delete a document
     * @param {string} agreementId Agreement id
     * @param {'Agreement' | 'AdhesionRequest'} documentType Document type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public deleteDocument(agreementId: string, documentType: 'Agreement' | 'AdhesionRequest', options?: any) {
        return DocumentApiFp(this.configuration).deleteDocument(agreementId, documentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get documents
     * @param {string} agreementId Agreement id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public getDocuments(agreementId: string, options?: any) {
        return DocumentApiFp(this.configuration).getDocuments(agreementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a document
     * @param {string} agreementId Agreement id
     * @param {'Agreement' | 'AdhesionRequest'} documentType Document type
     * @param {any} document 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public uploadDocument(agreementId: string, documentType: 'Agreement' | 'AdhesionRequest', document: any, options?: any) {
        return DocumentApiFp(this.configuration).uploadDocument(agreementId, documentType, document, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExportsApi - axios parameter creator
 * @export
 */
export const ExportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download an export of agreements in CSV format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAgreements: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/export/agreements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download an export of eyca discounts in CSV format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEycaDiscounts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/export/eyca-discounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send eyca discounts to EYCA via client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEycaDiscounts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/export/send-eyca-discounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExportsApi - functional programming interface
 * @export
 */
export const ExportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download an export of agreements in CSV format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportAgreements(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportAgreements(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download an export of eyca discounts in CSV format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportEycaDiscounts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportEycaDiscounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send eyca discounts to EYCA via client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEycaDiscounts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEycaDiscounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExportsApi - factory interface
 * @export
 */
export const ExportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Download an export of agreements in CSV format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAgreements(options?: any): AxiosPromise<any> {
            return localVarFp.exportAgreements(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download an export of eyca discounts in CSV format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEycaDiscounts(options?: any): AxiosPromise<any> {
            return localVarFp.exportEycaDiscounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send eyca discounts to EYCA via client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEycaDiscounts(options?: any): AxiosPromise<string> {
            return localVarFp.sendEycaDiscounts(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExportsApi - object-oriented interface
 * @export
 * @class ExportsApi
 * @extends {BaseAPI}
 */
export class ExportsApi extends BaseAPI {
    /**
     * 
     * @summary Download an export of agreements in CSV format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportsApi
     */
    public exportAgreements(options?: any) {
        return ExportsApiFp(this.configuration).exportAgreements(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download an export of eyca discounts in CSV format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportsApi
     */
    public exportEycaDiscounts(options?: any) {
        return ExportsApiFp(this.configuration).exportEycaDiscounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send eyca discounts to EYCA via client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportsApi
     */
    public sendEycaDiscounts(options?: any) {
        return ExportsApiFp(this.configuration).sendEycaDiscounts(options).then((request) => request(this.axios, this.basePath));
    }
}


